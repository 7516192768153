<template>
  <BaseAppearanceRoot data-test-id="app">
    <template v-if="!isLoading">
      <RouterView />
      <CookieConsent />
    </template>
    <template v-else>
      <UISkeletonSPA />
    </template>
  </BaseAppearanceRoot>
  <AuthWatchdog />
  <Notifications
    width="300px"
    position="bottom center"
    :speed="250"
  />
</template>

<script async lang="ts" setup>
import { useRouter } from 'vue-router';

import { registerHiddenFeatures } from '@/composables/use-hidden-feature';
import { useI18nLocale } from '@/composables/use-i18n-locale';
import { usePageTitle } from '@/composables/use-page-title';
import { useRemoteTranslations } from '@/composables/use-remote-translations';
import { useSetupData } from '@/composables/use-setup-data';
import { useVeeValidate } from '@/composables/use-vee-validate';
import AuthWatchdog from '@/modules/auth/components/AuthWatchdog.vue';
import BaseAppearanceRoot from '@/modules/base/components/BaseAppearanceRoot.vue';
import CookieConsent from '@/modules/cookie/components/CookieConsent.vue';
import UISkeletonSPA from '@/modules/ui-skeletons/components/UISkeletonSPA.vue';
import { useAuthStore } from '@/stores/auth-store';

registerHiddenFeatures();
useAuthStore();
useSetupData();
usePageTitle();
useVeeValidate();
useI18nLocale();

const { isLoading } = useRemoteTranslations();
const router = useRouter();

router.onError((error, to) => {
  const errors = ['Failed to fetch dynamically imported module', 'Importing a module script failed'];
  if (errors.some((e) => error.message.includes(e))) {
    window.location.href = to.fullPath;
  }
});
</script>

<style>
.jgpts .jgptc_button-container {
  bottom: 40px !important;
}

.jgpts .jgptc_container.jgptc_open .jgptc_iframe-container {
  bottom: 100px !important;
}
</style>

import { isArray } from 'lodash-es';

import type { IEnv } from '@/types/env-types';

/**
 * checks if the current environment matches the passed string or is included in the given string array.
 * @param env
 */
export function isEnv(env: IEnv | IEnv[]): boolean {
  const currentEnv = getEnv();
  const envs = isArray(env) ? env : [env];
  return envs.includes(currentEnv);
}

export function getEnv() {
  return import.meta.env.VITE_APP_ENV as IEnv;
}

export function getRecaptchaKey() {
  return import.meta.env.VITE_APP_RECAPTCHA_KEY;
}

export function getStripePublicKey() {
  return import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY;
}

export function getApiEndpoint() {
  return import.meta.env.VITE_APP_ENDPOINT;
}

export function appendHost(url: string | null | undefined): string {
  url = url || '';

  if (url.startsWith('http:') || url.startsWith('https:')) {
    return url;
  }

  if (url.startsWith('/')) {
    url = url.substring(1);
  }

  return `${window.location.origin}/${url}`;
}

export function getAuthSettings() {
  const domain = import.meta.env.VITE_APP_AUTH_DOMAIN as string;

  return {
    changePasswordUrl: import.meta.env.VITE_APP_AUTH_PASSWORD_CHANGE_URL as string,
    clientId: import.meta.env.VITE_APP_AUTH_CLIENT_ID as string,
    clientSecret: import.meta.env.VITE_APP_AUTH_CLIENT_SECRET as string,

    callbackUrl: appendHost(import.meta.env.VITE_APP_AUTH_CALLBACK_URL as string),
    callbackLogoutUrl: appendHost(import.meta.env.VITE_APP_AUTH_CALLBACK_LOGOUT_URL as string),
    callbackLoginUrl: appendHost(import.meta.env.VITE_APP_AUTH_CALLBACK_LOGIN_URL as string),

    checkUrl: import.meta.env.VITE_APP_AUTH_CHECK_URL as string,
    authorizeUrl: `${domain}/authorize`,
    tokenUrl: `${domain}/token`,
    logoutUrl: import.meta.env.VITE_APP_AUTH_LOGOUT_URL as string,
    scope: import.meta.env.VITE_APP_AUTH_SCOPE as string,
  };
}

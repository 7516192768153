import axios from 'axios';
import type { Ref } from 'vue';

import { getHost } from '@/composables/get-host';
import { i18n } from '@/config/i18n-config';
import { getAuthSettings } from '@/utils/env-utils';

const { callbackUrl, clientId, clientSecret } = getAuthSettings();

const authHttpClient = axios.create();

export function getToken(code: string) {
  const { tokenUrl, scope } = getAuthSettings();

  return authHttpClient.post(
    tokenUrl,
    {
      code,
      scope,
      grant_type: 'authorization_code',
      client_id: clientId,
      client_secret: clientSecret,
      redirect_uri: callbackUrl,
    },
    { withCredentials: true },
  );
}

export function refreshToken(refreshToken: string) {
  const { tokenUrl, scope } = getAuthSettings();

  return authHttpClient.post(
    tokenUrl,
    {
      scope,
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
      client_id: clientId,
      client_secret: clientSecret,
    },
    { withCredentials: true },
  );
}

export function getLoginRedirectUrl(code: string) {
  const { scope } = getAuthSettings();

  const params: Record<string, string> = {
    state: code,
    scope,
    response_type: 'code',
    client_id: clientId,
    ...getDefaultParams(callbackUrl),
  };

  const { authorizeUrl } = getAuthSettings();
  return authorizeUrl + '?' + paramsToQuery(params);
}

export function getLogoutRedirectUrl(token?: string) {
  const { logoutUrl, callbackLoginUrl } = getAuthSettings();

  let redirectUrl = callbackLoginUrl;
  if (!callbackLoginUrl.startsWith('http')) {
    redirectUrl = getFullUrlWithCurrentOrigin(callbackLoginUrl);
  }

  const params = getDefaultParams(redirectUrl);

  if (token) {
    params.token = token;
  }

  return logoutUrl + '?' + paramsToQuery(params);
}

export function getChangePasswordUrl() {
  const { changePasswordUrl, callbackLogoutUrl } = getAuthSettings();

  let redirectUrl = callbackLogoutUrl;
  if (!redirectUrl.startsWith('http')) {
    redirectUrl = getFullUrlWithCurrentOrigin(redirectUrl);
  }

  const query = paramsToQuery(getDefaultParams(redirectUrl));

  return `${changePasswordUrl}?${query}`;
}

function getDefaultParams(redirectUrl?: string) {
  let language: string;

  try {
    language = (i18n.global.locale as unknown as Ref<string>).value;
  } catch (e) {
    language = 'en';
  }

  const params: Record<string, string> = {
    host: getHost() || '',
    language,
  };

  if (redirectUrl) {
    params.redirect_uri = redirectUrl;
  }

  return params;
}

function paramsToQuery(params: ReturnType<typeof getDefaultParams>) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
}

function getFullUrlWithCurrentOrigin(givenPath: string) {
  let path = givenPath;
  // remove leading slash
  if (path.charAt(0) == '/') {
    path = path.substring(1);
  }

  return `${window.location.origin}/${path}`;
}

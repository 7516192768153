import dayjs from 'dayjs';
import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, type ComputedRef, reactive } from 'vue';
import { type RouteLocationNamedRaw, useRouter } from 'vue-router';

import { useMoveInDate } from '@/composables/use-move-in-date';
import {
  bookingFlowPageOder,
  bookingFlowRouteNames,
  routesFactoryBookingFlow,
} from '@/modules/booking-flow/booking-flow-routes';
import { useBookingFlowStorePersistence } from '@/modules/booking-flow/composables/use-booking-flow-store-persistence';
import type {
  IBookingFlowPredefinedSessionData,
  IBookingOptionSection,
  ICustomerData,
  IProduct,
} from '@/modules/booking-flow/types/booking-flow-types';
import type { IBookingPlan, IInsurance, IUnitType, IUnitTypeCategory } from '@/types';
import type { Maybe } from '@/types/utility-types';
import { getCustomerFormDataAndValidate } from '@/utils/customer-utils';
import { formatISODate } from '@/utils/date-utils';
import { parseToInteger } from '@/utils/format-utils';

export interface IBookingFlowStateModel {
  sessionDataLoading: boolean;
  locationId: number | undefined;
  unitTypeCategory: IUnitTypeCategory | undefined;
  unitType: IUnitType | undefined;
  moveInDate: string | undefined;
  bookingPlan: IBookingPlan | undefined;
  insurance: IInsurance | undefined;
  discountCode: string | undefined;
  customerData: Partial<ICustomerData> | undefined;
  selectedProducts: IProduct[];
}

export type ICustomerFormProgressStatus = 'done' | 'in-progress' | 'pending';
export type IMoveInDateStatus = 'valid' | 'invalid-is-before' | 'invalid-is-after';

export interface IBookingFlowStateGetters {
  locationId: ComputedRef<number | undefined>;
  sessionDataLoading: ComputedRef<boolean>;
  unitTypeCategory: ComputedRef<IUnitTypeCategory | undefined>;
  unitType: ComputedRef<IUnitType | undefined>;
  moveInDate: ComputedRef<string | undefined>;
  moveInDateStatus: ComputedRef<undefined | IMoveInDateStatus>;
  bookingPlan: ComputedRef<IBookingPlan | undefined>;
  insurance: ComputedRef<IInsurance | undefined>;
  customerData: ComputedRef<Partial<ICustomerData> | undefined>;
  fullName: ComputedRef<string | undefined>;
  countryId: ComputedRef<string | undefined>;
  discountCode: ComputedRef<IBookingFlowStateModel['discountCode']>;

  // Helpers
  /**
   * The best router location depending on the state data
   */
  currentSuitableRouteTarget: ComputedRef<RouteLocationNamedRaw>;
  stepUnitSelectionDone: ComputedRef<boolean>;
  stepMoveInDateDone: ComputedRef<boolean>;
  stepBookingPlanDone: ComputedRef<boolean>;
  stepNeedsInsurance: ComputedRef<boolean>;
  stepInsuranceDone: ComputedRef<boolean>;
  stepOptionsDone: ComputedRef<boolean>;
  stepOptionsOpenedSection: ComputedRef<IBookingOptionSection>;
  stepCustomerDataStatus: ComputedRef<ICustomerFormProgressStatus>;
  stepCustomerDataDone: ComputedRef<boolean>;

  selectedProducts: ComputedRef<IProduct[]>;
  oneTimeProducts: ComputedRef<IProduct[]>;
  recurringProducts: ComputedRef<IProduct[]>;
}

function getCopy<T>(item: T | undefined): T | undefined {
  if (!item) {
    return item;
  }

  return cloneDeep(item);
}

function getDefaultState(): IBookingFlowStateModel {
  return {
    sessionDataLoading: false,
    locationId: undefined,
    unitTypeCategory: undefined,
    unitType: undefined,
    moveInDate: undefined,
    bookingPlan: undefined,
    insurance: undefined,
    discountCode: undefined,
    customerData: undefined,
    selectedProducts: [],
  };
}

export const useBookingFlowStore = defineStore('booking-flow', () => {
  const state = reactive<IBookingFlowStateModel>(getDefaultState());
  const router = useRouter();
  const { save, saveDebounced, get: getSessionData } = useBookingFlowStorePersistence();
  const { minDate, maxDate } = useMoveInDate();

  async function saveSessionDataToServer() {
    return save(getDataToSave(state));
  }

  /**
   * Returns the new data to save or undefined if nothing has changed and no saving is needed
   * @param state
   */
  function getDataToSave(state: IBookingFlowStateModel): IBookingFlowPredefinedSessionData {
    return {
      locationId: state.locationId || undefined,
      discountCode: state.discountCode || undefined,
      unitType: state.unitType || undefined,
      moveInDate: state.moveInDate || undefined,
      customerData: state.customerData || undefined,
      insurance: state.insurance || undefined,
      unitTypeCategory: state.unitTypeCategory || undefined,
      bookingPlan: state.bookingPlan || undefined,
      selectedProducts: state.selectedProducts || undefined,
    };
  }

  const getters: IBookingFlowStateGetters = {
    locationId: computed(() => state.locationId),
    sessionDataLoading: computed(() => state.sessionDataLoading),
    unitTypeCategory: computed(() => state.unitTypeCategory),
    unitType: computed(() => state.unitType),
    moveInDate: computed(() => state.moveInDate),
    moveInDateStatus: computed<IMoveInDateStatus | undefined>(() => {
      if (!state.moveInDate) {
        return undefined;
      }

      const isBefore = dayjs(state.moveInDate).isBefore(minDate.value, 'day');

      if (isBefore) {
        return 'invalid-is-before';
      }

      const isAfter = dayjs(state.moveInDate).isAfter(maxDate.value, 'day');

      if (isAfter) {
        return 'invalid-is-after';
      }

      return 'valid';
    }),
    bookingPlan: computed(() => state.bookingPlan),
    insurance: computed(() => state.insurance),
    customerData: computed(() => state.customerData),
    fullName: computed(() => {
      return (
        [getters.customerData.value?.firstName, getters.customerData.value?.lastName]
          .filter((name) => !!name)
          .join(' ') || undefined
      );
    }),
    countryId: computed(() => state.customerData?.country?.id),
    discountCode: computed(() => state.discountCode || undefined),

    // Options page helpers:
    stepUnitSelectionDone: computed(() => !!state.unitType),
    stepMoveInDateDone: computed(
      () => getters.stepUnitSelectionDone.value && !!state.moveInDate && getters.moveInDateStatus.value === 'valid',
    ),
    stepBookingPlanDone: computed(() => getters.stepMoveInDateDone.value && !!state.bookingPlan),
    stepNeedsInsurance: computed(() => getters.stepBookingPlanDone.value && !!state.bookingPlan?.hasInsurances),
    stepInsuranceDone: computed(
      () =>
        (getters.stepBookingPlanDone.value && getters.stepNeedsInsurance.value && !!state.insurance) ||
        (getters.stepBookingPlanDone.value && !getters.stepNeedsInsurance.value),
    ),
    stepOptionsDone: computed(() => getters.stepInsuranceDone.value),
    stepOptionsOpenedSection: computed<IBookingOptionSection>(() => {
      switch (true) {
        case !getters.stepMoveInDateDone.value:
          return 'moveInDate';
        case !getters.stepBookingPlanDone.value:
          return 'billingPeriod';
        case getters.stepNeedsInsurance.value && !getters.stepInsuranceDone.value:
          return 'insurance';
        default:
          return null;
      }
    }),
    stepCustomerDataStatus: computed(() => {
      if (state.customerData == null) {
        return 'pending';
      }

      try {
        getCustomerFormDataAndValidate(state.customerData);
        return 'done';
      } catch (e) {
        return 'in-progress';
      }
    }),
    stepCustomerDataDone: computed(
      () => getters.stepOptionsDone.value && getters.stepCustomerDataStatus.value === 'done',
    ),
    currentSuitableRouteTarget: computed<RouteLocationNamedRaw>(() => {
      const unitTypeId = getters.unitType.value?.id;
      const currentRoute = router.currentRoute.value;

      // defining the flow of the pages here:
      const pageOrder = bookingFlowPageOder;

      /**
       * stores the page the user should go to according to the booking flow selection. meaning: the latest page possible.
       */
      let desiredPage: string = bookingFlowRouteNames.unitSelection;

      switch (true) {
        case getters.stepCustomerDataDone.value:
        case getters.stepOptionsDone.value && getters.stepCustomerDataStatus.value === 'in-progress':
          desiredPage = bookingFlowRouteNames.customerForm;
          break;
        case getters.stepOptionsDone.value:
        case getters.stepInsuranceDone.value:
          desiredPage = bookingFlowRouteNames.bookingBasket;
          break;
        case getters.stepUnitSelectionDone.value:
        case getters.stepMoveInDateDone.value:
        case getters.stepBookingPlanDone.value && getters.stepNeedsInsurance.value:
          desiredPage = bookingFlowRouteNames.bookingOptions;
          break;
      }

      const currentRouteName = String(currentRoute.name || '');
      /**
       * The index of the current route in the booking flow
       */
      const currentIndex = pageOrder.indexOf(currentRouteName);
      /**
       * The index of the desired route in the booking flow according to the user selections
       */
      const desiredIndex = pageOrder.indexOf(desiredPage);

      // If the current route is before the last allowed page let the user navigate to this one.
      // Else: go to the desired page by the state, meaning: the user wanted to access a later page in the flow that the selections would allow.
      // --> Move them back to the latest allowed page.
      if (currentIndex > -1 && currentIndex <= desiredIndex) {
        desiredPage = currentRouteName;
      }

      switch (desiredPage) {
        case bookingFlowRouteNames.customerForm:
          return routesFactoryBookingFlow.customerForm(unitTypeId);
        case bookingFlowRouteNames.bookingBasket:
          return routesFactoryBookingFlow.bookingBasket(unitTypeId);
        case bookingFlowRouteNames.bookingOptions:
          return routesFactoryBookingFlow.bookingOptions(unitTypeId, getters.stepOptionsOpenedSection.value);
        default:
          // If query parameter for the unitTypeCategory is present: use it otherwise fallback to the one of the state
          return routesFactoryBookingFlow.unitTypeSelection(
            parseToInteger(currentRoute.query?.unitTypeCategoryId, false) || getters.unitTypeCategory.value?.id,
          );
      }
    }),

    selectedProducts: computed(() => state.selectedProducts),
    oneTimeProducts: computed(() => state.selectedProducts.filter((p) => p.frequency === 'one_time')),
    recurringProducts: computed(() => state.selectedProducts.filter((p) => p.frequency === 'recurring')),
  };

  /**
   * Contains only state mutations. no side effects like API requests!
   */
  const mutations = {
    setLocationId(locationId: Maybe<number>) {
      state.locationId = parseToInteger(locationId, undefined);
    },
    setSessionData(data: IBookingFlowPredefinedSessionData) {
      state.locationId = data.locationId || undefined;
      state.unitTypeCategory = data.unitTypeCategory || undefined;
      state.unitType = data.unitType || undefined;
      state.moveInDate = data.moveInDate || undefined;
      state.bookingPlan = data.bookingPlan || undefined;
      state.insurance = data.insurance || undefined;
      state.customerData = data.customerData || undefined;
      state.discountCode = data.discountCode || undefined;
      state.selectedProducts = data.selectedProducts || [];
    },
    setUnitTypeCategory(unitTypeCategory: IUnitTypeCategory | undefined) {
      state.unitTypeCategory = getCopy(unitTypeCategory);

      if (unitTypeCategory?.id != null && state.unitType && !state.unitType.categoryIds.includes(unitTypeCategory.id)) {
        mutations.setUnitType(undefined);
      }
    },
    setUnitType(unitType: IUnitType | undefined) {
      state.unitType = getCopy(unitType);
      state.moveInDate = undefined;
      state.bookingPlan = undefined;
      state.insurance = undefined;
      state.discountCode = undefined;
    },
    replaceUnitType(unitType: IUnitType | undefined) {
      state.unitType = getCopy(unitType);
    },
    setMoveInDate(moveInDate: string | undefined) {
      state.moveInDate = moveInDate;
      state.bookingPlan = undefined;
      state.insurance = undefined;
      state.discountCode = undefined;
    },
    setBookingPlan(bookingPlan: IBookingPlan | undefined) {
      state.bookingPlan = bookingPlan;
      state.insurance = undefined;
      state.discountCode = undefined;
    },
    setInsurance(insurance: IInsurance | undefined) {
      state.insurance = insurance;
      state.discountCode = undefined;
    },
    setDiscountCode(discountCode: string | undefined) {
      state.discountCode = discountCode;
    },
    setCustomerData(customerData: Partial<ICustomerData> | undefined) {
      state.customerData = customerData;
    },
    patchCustomerData(customerData: Partial<ICustomerData> | undefined) {
      state.customerData = {
        ...(state.customerData || {}),
        ...JSON.parse(JSON.stringify(customerData || {})),
      };
    },
    resetFlowToUnitTypeSelection() {
      state.unitType = undefined;
      state.moveInDate = undefined;
      state.bookingPlan = undefined;
      state.insurance = undefined;
      state.discountCode = undefined;
      state.customerData = undefined;
      state.selectedProducts = [];
    },

    reset() {
      state.unitType = undefined;
      state.moveInDate = undefined;
      state.bookingPlan = undefined;
      state.insurance = undefined;
      state.customerData = undefined;
      state.discountCode = undefined;
      state.customerData = undefined;
      state.selectedProducts = [];
    },

    toggleProduct(product: IProduct) {
      const index = state.selectedProducts.findIndex((p) => p.id === product.id);
      if (index === -1) {
        state.selectedProducts.push(product);
      } else {
        state.selectedProducts.splice(index, 1);
      }
    },
  };

  const actions = {
    async restoreSession() {
      state.sessionDataLoading = true;
      return getSessionData()
        .then((data) => this.setSessionData(data))
        .finally(() => (state.sessionDataLoading = false));
    },
    setLocationId(locationId: number | undefined) {
      mutations.setLocationId(locationId);
    },
    async setSessionData(data: IBookingFlowPredefinedSessionData) {
      mutations.setSessionData(data);
      return actions.validateSessionData();
    },

    async validateSessionData() {
      if (state.unitType == null) {
        return this.resetFlowToUnitTypeSelection();
      } else if (state.moveInDate == null) {
        mutations.setUnitType(state.unitType);
      } else if (state.bookingPlan == null) {
        mutations.setMoveInDate(state.moveInDate);
      } else if (getters.stepNeedsInsurance.value && state.insurance == null) {
        mutations.setBookingPlan(state.bookingPlan);
      }
    },

    async setUnitTypeCategory(unitTypeCategory: IUnitTypeCategory | undefined) {
      if (state.unitTypeCategory?.id === unitTypeCategory?.id) {
        return;
      }
      mutations.setUnitTypeCategory(unitTypeCategory);
      return saveSessionDataToServer();
    },

    async setUnitType(unitType: IUnitType | undefined) {
      if (state.unitType?.id === unitType?.id) {
        return;
      }

      mutations.setUnitType(unitType);
      return saveSessionDataToServer();
    },

    async replaceUnitType(unitType: IUnitType | undefined) {
      if (state.unitType?.id === unitType?.id) {
        return;
      }

      mutations.replaceUnitType(unitType);
      return saveSessionDataToServer();
    },

    async setMoveInDate(moveInDate: string | Date | undefined) {
      if (moveInDate && !state.unitType) {
        return;
      }

      const formattedMoveInDate = moveInDate ? formatISODate(moveInDate) : undefined;

      if (formattedMoveInDate === state.moveInDate) {
        return;
      }

      mutations.setMoveInDate(formattedMoveInDate);
      return saveSessionDataToServer();
    },

    async setBookingPlan(bookingPlan: IBookingPlan | undefined) {
      if (bookingPlan && (!state.moveInDate || state.bookingPlan?.id === bookingPlan?.id)) {
        return;
      }
      mutations.setBookingPlan(bookingPlan);
      return saveSessionDataToServer();
    },

    async setInsurance(insurance: IInsurance | undefined) {
      if (insurance && (!state.bookingPlan || state.bookingPlan.hasInsurances === false)) {
        return;
      }

      mutations.setInsurance(insurance);
      return saveSessionDataToServer();
    },

    async setDiscountCode(code: string | undefined) {
      if (code && !getters.stepOptionsDone.value) {
        return;
      }

      mutations.setDiscountCode(code);
      return saveSessionDataToServer();
    },

    async setCustomerData(customerData: Partial<ICustomerData> | undefined) {
      mutations.setCustomerData(customerData ? { ...customerData } : undefined);
      return saveDebounced(state);
    },

    async patchCustomerData(customerData: Partial<ICustomerData> | undefined) {
      mutations.patchCustomerData(customerData ? { ...customerData } : undefined);
      return saveDebounced(state);
    },

    async toggleProduct(product: IProduct) {
      mutations.toggleProduct(product);
      return saveSessionDataToServer();
    },

    async redirectBasedOnStateData() {
      if (getters.currentSuitableRouteTarget.value) {
        /*
         * The language parameter will be copied to the target url because they should not matter. Adding is easier than
         * removing. At the end the urls should contain the same not-important query parameters to allow comparing.
         * For instance: The language property should never matter, but query params like the section on the
         * options page do matter.
         */
        const lang = router.currentRoute.value?.query?.lang;
        const currentFullPath = router.currentRoute.value.fullPath;

        const target = {
          ...getters.currentSuitableRouteTarget.value,
          query: {
            ...getters.currentSuitableRouteTarget.value.query,
            lang,
          },
        };

        const nextFullPath = router.resolve(target)?.fullPath;

        if (nextFullPath !== currentFullPath) {
          return router.push(getters.currentSuitableRouteTarget.value);
        }
      }

      return;
    },

    /**
     * Resets the state but not the sessionId, the locationId and the chosen unitTypeCategory
     */
    async resetFlowToUnitTypeSelection() {
      if (
        state.unitType == null &&
        state.moveInDate == null &&
        state.bookingPlan == null &&
        state.insurance == null &&
        state.discountCode == null &&
        state.customerData == null
      ) {
        return;
      }
      mutations.resetFlowToUnitTypeSelection();
      return saveSessionDataToServer();
    },

    async reset() {
      mutations.reset();
      return saveSessionDataToServer();
    },
  };

  return {
    ...state,
    ...actions,
    ...getters,
    mutations,
  };
});

export type IUseBookingFlowStoreReturnType = ReturnType<typeof useBookingFlowStore>;
